import React from "react"
import styled from 'styled-components'
import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"

import SEO from "../components/seo"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"

const NotFoundPage = () => {



return (

  <>
  <ThemeProvider theme={theme}>
    <SEO title="404: Not found" />
    <Navbar />
  
    <h1>NIE ZNALEZIONO STRONY</h1>
    <p>404</p>
    
  
     <Footer />

 </ThemeProvider>
  </>
)
}

export default NotFoundPage
